import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import hgLogoColor from '../../assets/images/hg_logo-color.svg';
import { Content } from '../ui';

const Wrapper = styled.div`
  background-color: ${props => props.theme.bg};
`

const Header = styled.header`
  background-color: ${props => props.theme.light};
  border-bottom: 1px solid ${props => props.theme.gray_500};
  height: 96px;
  display: flex;
  justify-content: center;

  div {
    max-width: 1201px;
    width: 100%;
    display: flex;
    align-items: center;

    img {
      height: 72px;
      margin-right: 40px;
    }

    span.headline5 {
      color: ${props => props.theme.primary};
    }
  }
`

const Body = styled.div`
  background-color: ${props => props.theme.bg};
  max-width: 1201px;
  width: 100%;
  margin: 24px auto;

  table, th, td {
    border-collapse: collapse;
    border: 1px solid ${props => props.theme.dark};
  }

  table {
    border-collapse: collapse
  }

  th {
    background: ${props => props.theme.dark};
  }

  th, td {
    padding: 12px;
    vertical-align: middle;
  }
`
export default () => (
  <Wrapper>
    <Header>
      <div>
        <Link to="/"><img src={hgLogoColor} alt="HiveGrid Logo" /></Link>
      </div>
    </Header>
    <Body>
      <Content>
        <h4>
          Term of Service
        </h4>
        <p>
          HiveGround, Co. Ltd. offers a cloud-based software platform for Unmanned Aerial Vehicles (“Service”).  The platform consists of 2 applications working together.
        </p>
        <ul>
          <li>
            <strong>HiveGrid</strong>: Enterprise Resource Planning Application
          </li>
          <li>
            <strong>HGMC</strong>: Unmanned Vehicle Ground Controller Application
          </li>
        </ul>
        <p>This agreement, the Terms of Service (“Terms”), outlines the terms regarding your use of our products and services. These Terms are a legally binding contract between you and HiveGround, Co. Ltd. (&quot;HiveGround&quot;, “we” or “us”) so please read carefully. If you do not agree with these Terms, you may not register or use any of the Services.</p>
        <h4>
          General Terms of Service
        </h4>
        <h5>
          Eligibility
        </h5>
        <p>
          You must be at least 18 years of age to use the Service.
        </p>
        <p>
          By agreeing to the &quot;Terms&quot;, you represent and warrant to us that
        </p>
        <ol>
          <li>
            You are at least 18 years of age
          </li>
          <li>
            You have not previously been suspended or removed from the Service
          </li>
          <li>
            Your registration and your use of the Service is in compliance with all applicable laws and regulations.
          </li>
        </ol>
        <p>
          If you are using the Service on behalf of an entity, organization, or company, you represent and warrant that you have the authority to bind that organization to these Terms and you agree to be bound by these Terms on behalf of that organization.
        </p>
        <h5>
          User Accounts and Registration
        </h5>
        <p>
          To access certain features of the Service, you must register for and sign in with a HiveGrid account.  Registering for a HiveGrid account, you will be required to provide us with some personal information, such as email address or other contact information.  You represent and warrant that the information you provide is accurate and that you will keep it up-to-date at all times. When you register, you will be asked to provide a password.  You are solely responsible for maintaining the confidentiality of your HiveGrid account and password, and you accept responsibility for all activities that occur under your account.
        </p>
        <h5>
          Account Verification
        </h5>
        <p>
          HiveGround may verify your account by reviewing the validity of personal credential, including but not limited email address and mobile phone number.  Your privacy is our priority, however, you agree that HiveGround may return credential information and provide such information, as well as your user login ID or email address, to government authorities, law enforcement agencies, regulatory agencies and other duly authorized parties as may be required by or permitted under applicable laws.
        </p>
        <p>
          Account verification may expire and renewal is required at intervals determined by HiveGround and subject to change at HiveGround' sole discretion. HiveGround reserves the right to revoke account verification at its own discretion, for any or no reason.
        </p>
        <h5>
          Prohibited Conduct
        </h5>
        <p>
          By using the service YOU AGREE NOT TO:
        </p>
        <ol>
          <li>
            Use the Service for any illegal purpose or in violation of any local, national, or international law or regulations.
          </li>
          <li>
            Violate, or encourage others to violate, any right of a third party including by infringing or misappropriating any third party intellectual property rights or violating any third party's rights to privacy.
          </li>
          <li>
            Interfere with security-related features of the Service, including by
          </li>
          <ol>
            <li>
              disabling or circumventing features that prevent or limit use or copying of any content or
            </li>
            <li>
              reverse engineering or otherwise attempting to discover the source code of any portion of the Service except to the extent that the activity is expressly permitted by applicable law
            </li>
          </ol>
          <li>
            Interfere with the operation of the Service, including by
          </li>
          <ol>
            <li>
              uploading or otherwise disseminating any virus, adware, spyware, worm, or other malicious code
            </li>
            <li>
              making any unsolicited offer or advertisement to another user of the Service
            </li>
            <li>
              attempting to collect personal information about another user or third party without consent
            </li>
            <li>
              interfering with or disrupting any network, equipment, or server connected to or used to provide the Service
            </li>
          </ol>
        </ol>
        <h5>
          Termination of Use
        </h5>
        <p>
          If you violate any provision of these Terms, your permission from us to use the Service will terminate automatically. In addition, HiveGround may in its sole discretion terminate your HiveGrid account or suspend or terminate your access to the Service at any time for any reason or no reason, with or without notice. We also reserve the right to modify or discontinue the Service or features of the Service at any time, temporarily or permanently, without prior notice. We will have no liability whatsoever on account of any change to the Service or any suspension or termination of your access to or use of the Service. Without limiting the foregoing, you understand and agree that in any termination or suspension of your account or the Service, you will not have access to any data or content recorded using the Service or otherwise contained in the Service, and we will have no responsibility to provide you with access to such data or content. You may terminate your HiveGrid account at any time by contacting customer service at&nbsp;
          <a href="mailto:support@hiveground.com">
            support@hiveground.com
          </a>
          .
        </p>
        <h5>
          Modifications of these Terms
        </h5>
        <p>
          HiveGround reserve the right, at our discretion, to change these Terms on a going-forward basis at any time. Please check these Terms periodically for changes. Modified Terms of Use are effective upon its publication. If we make material changes to the Terms, we will attempt to provide you reasonable notice before such changes become effective. By continuing to use the Service, you agree to be bound by the updated, amended, or modified Terms. If you do not agree to be bound by the modified Terms, you must immediately cease your use of the Service. Disputes arising under these Terms will be resolved in accordance with the version of these Terms that was in effect at the time the dispute arose.
        </p>
        <h5>
          Indemnity
        </h5>
        <p>
          You are responsible for your use of the Service. You will defend and indemnify HiveGround and its officers, directors, employees, consultants, affiliates, subsidiaries and agents (together, the “HiveGround Entities”) from and against any claim, liability, damage, loss, and expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with:
        </p>
        <ul>
          <li>
            your access to, use of, or alleged use of, the Service;
          </li>
          <li>
            your violation of any portion of these Terms, any representation, warranty, or agreement referenced in these Terms, or any applicable law or regulation;
          </li>
          <li>
            your violation of any third-party rights, including any intellectual property rights or publicity, confidentiality, other property, or privacy, right; or
          </li>
          <li>
            any dispute or issue between you and any third party. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you (without limiting your indemnification obligations with respect to that matter), and in that case, you agree to cooperate with our defense of that claim.
          </li>
        </ul>
        <h5>
          Disclaimers; No Warranties
        </h5>
        <p>
          THE SERVICE AND ALL MATERIALS, AND CONTENT AVAILABLE THROUGH THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS, WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED. THE HIVEGROUND ENTITIES DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICE AND ALL MATERIALS, AND CONTENT AVAILABLE THROUGH THE SERVICE, INCLUDING:
        </p>
        <ul>
          <li>
            ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND
          </li>
          <li>
            ANY WARRANTY ARISING OUT OF A COURSE OF DEALING, USAGE, OR TRADE. THE HIVEGROUND ENTITIES DO NOT WARRANT THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS, FLIGHT ENVIRONMENT DATA, OR CONTENT OFFERED THROUGH THE SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND DO NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.
          </li>
        </ul>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR ANY MATERIALS, OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF THE HIVEGROUND ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY STATED IN THESE TERMS. YOU ASSUME ALL RISK FOR ANY DAMAGE THAT MAY RESULT FROM YOUR USE OF OR ACCESS TO THE SERVICE AND ANY MATERIALS, FLIGHT ENVIRONMENT DATA, OR CONTENT AVAILABLE THROUGH THE SERVICE. YOU UNDERSTAND AND AGREE THAT YOU USE THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT YOU ARE SOLELY RESPONSIBLE FOR ANY PERSONAL INJURY, DEATH, DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR ANY HARDWARE USED IN CONNECTION WITH THE SERVICE) OR THIRD PARTY PROPERTY, OR THE LOSS OF DATA THAT RESULTS FROM YOUR USE OF OR INABILITY TO USE THE SERVICE.
        </p>
        <p>
          SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.
        </p>
        <h5>
          Limitation of Liability
        </h5>
        <p>
          IN NO EVENT WILL THE HIVEGROUND ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS, OR CONTENT ON THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY HIVEGROUND ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGES.
        </p>
        <h5>
          Dispute Resolution
        </h5>
        <p>
          These Terms provide that all disputes between you and HiveGround will be resolved by BINDING ARBITRATION. You agree to give up your right to go to court to assert or defend your rights under this contract, except for matters that may be taken to small claims court. Your rights will be determined by a neutral arbitrator and not a judge or jury, and your claims cannot be brought as a class action.
        </p>
        <h4>
          HiveGrid Terms of Service
        </h4>
        <h5>
          Usage of User Information
        </h5>
        <p>
          Please refer to our <Link onClick={() => window.scrollTo(0, 0)} to="/privacy-policy">Privacy Policy</Link> for information and terms.
        </p>
        <h5>
          Billing
        </h5>
        <h6>
          Billing Cycles
        </h6>
        <p>
          Billing cycles start immediately, on the day you signed up.
        </p>
        <h6>
          Pre-payment and Automatic Billing
        </h6>
        <p>
          We will bill you for your usage of HiveGrid at the beginning of your billing cycle. Your billing cycle duration depends on the subscription plan (monthly or annually).
        </p>
        <h6>
          Changes of Plan
        </h6>
        <p>
          <em>
            Upgrades:&nbsp;
          </em>
          If you choose to upgrade your plan, you will be immediately charged the prorated amount of the upgraded plan in the current month, together with your payment for the following month.
        </p>
        <p>
          <em>
            Downgrades:&nbsp;
          </em>
          If you choose to downgrade your plan, you will be charged the new amount on the subsequent billing date.
        </p>
        <p>
          <em>
            Cancellations:&nbsp;
          </em>
          If you choose to cancel your plan, you will no longer be billed from the next billing cycle onwards.
        </p>
        <p>
          <em>
            Reactivations:&nbsp;
          </em>
          You can log in to HiveGrid to reactivate your cancelled subscription at any time.
        </p>
        <h4>
          HGMC Terms of Service
        </h4>
        <h5>
          HGMC Service Overview
        </h5>
        <p>
          You may use HGMC to plan flight paths, control certain models of drone, as listed in the Supported Drone, and to upload flights information to HiveGround' cloud-based enterprise resource planning platform, HiveGrid.
        </p>
        <h5>
          Your Obligations
        </h5>
        <p>
          You are responsible for obtaining and maintaining all hardware and other communications equipment needed to access or use the Services. You agree that :
        </p>
        <ul>
          <li>
            you will use each hardware only in conformity with the applicable hardware terms of use, user manual, and safety guidelines;
          </li>
          <li>
            you will use and maintain with due care, and will not deface, modify, use or operate any hardware in any illegal manner or for any illegal purpose in violation of any applicable applicable local, national and international laws or regulations;
          </li>
          <li>
            you will not (and will not permit others to) remove, deface, or otherwise obstruct any regulatory or certification markers affixed to hardware.
          </li>
        </ul>
        <p>
          When using the HGMC to operate a supported drone, you agree to abide by all relevant statutes, rules and regulations set forth by government, law enforcement, and regulatory agencies.
          You will obtain and maintain all necessary licenses, consents, and authorizations of any kind.
        </p>
        <p>
          By entering into these Terms, you represent and warrant that your use of the Service will comply with all applicable laws, statutes, and regulations, and that you will not use the Service except as expressly permitted under these Terms.
        </p>
        <h4>
          Supported Drones
        </h4>
        <h5>
          Agricultural Drones
        </h5>
        <table>
          <tbody>
            <tr>
              <th>Drone Model</th>
              <th>Manufacturer</th>
            </tr>
            <tr>
              <td>Tiger Drone TGD1X</td>
              <td>HiveGround</td>
            </tr>
            <tr>
              <td>Tiger Drone TGD1S</td>
              <td>HiveGround</td>
            </tr>
          </tbody>
        </table>
        <h5>
          Survey Drones
        </h5>
        <table>
          <tbody>
            <tr>
              <th>
                <p>
                  <strong>Drone Model</strong>
                </p>
              </th>
              <th>
                <p>
                  <strong>Manufacturer</strong>
                </p>
              </th>
              <th>
                <p>
                  <strong>Camera</strong>
                </p>
              </th>
            </tr>
            <tr>
              <td rowspan="2">
                Vespa Hex
              </td>
              <td rowspan="2">
                HiveGround
              </td>
              <td>
                MicaSense RedEdge &amp; Altum Series cameras
              </td>
            </tr>
            <tr>
              <td>
                Sony RX0 Series
              </td>
            </tr>
            <tr>
              <td>
                Phantom 4
              </td>
              <td>
                DJI
              </td>
              <td>
              </td>
            </tr>
          </tbody>
        </table>
        <h4>
          Supported Devices
        </h4>
        <p>
          HiveGround offers official support of HGMC for the following Android Devices
        </p>
        <h5>
          Google Devices
        </h5>
        <ul>
          <li>
            Google Pixel
          </li>
          <li>
            Google Pixel 2, 2XL
          </li>
          <li>
            Google Pixel 3, 3XL
          </li>
        </ul>
        <h5>
          Samsung Devices
        </h5>
        <ul>
          <li>
            Samsung Galaxy S10, S10+
          </li>
          <li>
            Samsung Galaxy S9, S9+
          </li>
          <li>
            Samsung Galaxy S8, S8+
          </li>
          <li>
            Samsung Galaxy Note 9
          </li>
          <li>
            Samsung Galaxy Note 8
          </li>
          <li>
            Samsung Galaxy Note 7
          </li>
          <li>
            Samsung Galaxy Tab S4
          </li>
          <li>
            Samsung Galaxy Tab S3
          </li>
          <li>
            Samsung Galaxy Tab A
          </li>
          <li>
            Samsung Galaxy Tab Active 2
          </li>
        </ul>
        <p>
          Please be aware that there are over 1000 different Android device models, and we are unable to provide support or testing for all of them. If your device is not listed above and we are unable to reproduce your issue on an officially supported device, our team may not be able to provide a resolution.
        </p>
      </Content>
    </Body>
  </Wrapper>
)
