import React, { Component } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { compose } from 'recompose';
import withIntl from '../../../../hoc/withIntl';
import styled from 'styled-components';
import Button from '../../general/Button';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`

const AvatarImg = styled.div`
  margin-right: 16px;
  img {
    height: 141px;
    width: 141px;
    border-radius: 5px;
  }
`

const AvatarInfo = styled.span.attrs({
  className: 'sub2'
})`
  /* color: ${props => props.theme.gray_900}; */
  display: block;
`

const AvatarInfoAndController = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const AvatarController = styled.div`
  display: flex;
  align-items: flex-end;
`

const Uploader = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-right: 8px;

  input[type="file"] { 
    display: none;
  }
`

const Reset = styled(Button).attrs({
  size: 'medium',
  type: 'reset'
})`  
  width: auto;
  min-width: 109px;
  padding-left: 12px;
  padding-right: 12px;
`

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => {
    callback(reader.result);
  });
  reader.readAsDataURL(img);
}

class AvatarUploader extends Component {

  state = {

  }

  triggerInputFile = () => {
    this.fileInput.click();
  }

  clearImg = () => {
    this.setState({ fileName: null });
    this.props.onChange({ target: { value: null } });
    this.fileInput.value = "";
  }

  onProfilePictureChange = (e) => {
    const fileName = e.target.files[0].name;
    getBase64(e.target.files[0], imageUrl => {
      this.setState({ fileName });
      this.props.onChange({ target: { value: imageUrl.split(',')[1] } })
    });
  }

  render() {
    const { t, imgSrc } = this.props;
    const isImageExisted = !(imgSrc === '' || !imgSrc);

    return (
      <Wrapper>
        <AvatarImg>
          {
            isImageExisted ? <img alt="avatar" src={`data:image/jpg;base64, ${this.props.imgSrc}`} /> : <Avatar shape="square" size={141} icon={<UserOutlined />} />
          }
        </AvatarImg>
        <AvatarInfoAndController>
          <div>
            <AvatarInfo>{this.state.fileName || t('ui:avatar_uploader.select_image_text', 'Please select an image file')}</AvatarInfo>
            {/* <AvatarInfo>800 x 800</AvatarInfo> */}
          </div>
          <AvatarController>
            <Uploader>
              <Button id="avatar-uploader-upload-button" size="medium" type="button" onClick={this.triggerInputFile}>{t(`ui:avatar_uploader.upload_image_button.${isImageExisted ? 'change' : 'upload'}`, 'UPLOAD IMAGE')}</Button>
              <input type="file" onChange={this.onProfilePictureChange} ref={fileInput => this.fileInput = fileInput} />
            </Uploader>
            <Reset id="avatar-uploader-reset-button" onClick={this.clearImg}>{t('ui:avatar_uploader.reset_button', 'RESET')}</Reset>
          </AvatarController>
        </AvatarInfoAndController>
      </Wrapper>
    )
  }
}

export default compose(
  withIntl('ui')
)(AvatarUploader);